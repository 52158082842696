import './Timeline.css';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import useDeviceDetection from '../../hooks/use-device-size';
import { useMemo, } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const Timeline = () => {
    const isLittleScreen = useDeviceDetection() !== 'Desktop';

    return (
        <div className='Timeline'>
            <h2 className='TimelineTitle'>DEMENAGEZ L’ESPRIT TRANQUILLE</h2>
            {isLittleScreen ? <MobileTimeline /> : <DesktopTimeline />}
        </div>
    );
};


const DesktopTimeline = () => {
    return (
        <div className='DesktopTimeline'>
            <ScrollAnimation animateIn='animate__fadeIn' animateOnce={true}>
                <Stepper alternativeLabel nonLinear activeStep={-1}>
                    <Step sx={{
                        '& .MuiStepIcon-root': {
                            marginTop: '-30px',
                            marginLeft: '-60px'
                        },
                        '& .MuiSvgIcon-root': {
                            height: '80px',
                            width: '80px',
                            color: '#fff',
                        },
                        '& .MuiStepIcon-text': {
                            fill: '#000',
                            fontFamily: 'Orelega One',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            textTransform: 'uppercase',
                        },
                    }}>
                        <StepLabel>
                            <div className='TimeLineStepLabel__container'>
                                <h4 className='TimeLineStepLabel__title'>évaluer votre besoin</h4>
                                <p className='TimeLineStepLabel__description'>
                                    Vous remplisser le formulaire de devis en ligne. Nos Experts en déménagement analysent votre besoin.
                                </p>
                            </div>
                        </StepLabel>
                    </Step>
                    <Step sx={{
                        '& .MuiStepIcon-root': {
                            marginTop: '-30px',
                            marginLeft: '0px',
                            zIndex: '1',
                        },
                        '& .MuiSvgIcon-root': {
                            height: '80px',
                            width: '80px',
                            color: '#fff',
                        },
                        '& .MuiStepIcon-text': {
                            fill: '#000',
                            fontFamily: 'Orelega One',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            textTransform: 'uppercase',
                        },
                    }}>
                        <StepLabel>
                            <div className='TimeLineStepLabel__container'>
                                <h4 className='TimeLineStepLabel__title'>RECEVEZ VOTRE DEVIS GRATUIT</h4>
                                <p className='TimeLineStepLabel__description'>
                                    Après analyse de votre besoin, un devis personnalisé vous sera envoyer par mel au meilleur prix.
                                </p>
                            </div>
                        </StepLabel>
                    </Step>
                    <Step sx={{
                        '& .MuiStepIcon-root': {
                            marginTop: '-30px',
                            marginLeft: '0px',
                            zIndex: '1',
                        },
                        '& .MuiSvgIcon-root': {
                            height: '80px',
                            width: '80px',
                            color: '#fff',
                        },
                        '& .MuiStepIcon-text': {
                            fill: '#000',
                            fontFamily: 'Orelega One',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            textTransform: 'uppercase',
                        },
                    }}>
                        <StepLabel>
                            <div className='TimeLineStepLabel__container'>
                                <h4 className='TimeLineStepLabel__title'>Mise en relation avec votre demenageur</h4>
                                <p className='TimeLineStepLabel__description'>
                                    Si le devis vous satisfait et que vous le valdier, vous serez mis en relation avec votre déménageur.
                                </p>
                            </div>
                        </StepLabel>
                    </Step>
                    <Step sx={{
                        '& .MuiStepIcon-root': {
                            marginTop: '-30px',
                            marginLeft: '60px',
                            zIndex: '1',
                        },
                        '& .MuiSvgIcon-root': {
                            height: '80px',
                            width: '80px',
                            color: '#fff',
                        },
                        '& .MuiStepIcon-text': {
                            fill: '#000',
                            fontFamily: 'Orelega One',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            textTransform: 'uppercase',
                        },
                    }}>
                        <StepLabel>
                            <div className='TimeLineStepLabel__container'>
                                <h4 className='TimeLineStepLabel__title'>LAISSER VOUS DEMENAGER</h4>
                                <p className='TimeLineStepLabel__description'>
                                    Nos experts déménageurs se présentent le jour J et reste à vos côtés jusqu’a votre enmenagement.
                                </p>
                            </div>
                        </StepLabel>
                    </Step>
                </Stepper>
            </ScrollAnimation>

        </div>
    );

}

const MobileTimeline = () => {

    const EmptyConnector = useMemo(() => () => false, []);

    return (
        <div className='MobileTimeline'>
            <ScrollAnimation animateIn='animate__fadeIn' animateOnce={true}>
                <Stepper orientation='vertical' connector={<EmptyConnector />} activeStep={-1}>
                    <Step sx={{
                        '& .MuiStepIcon-root': {
                            // marginLeft: '-60px'
                            // width: '100%'
                        },
                        '& .MuiSvgIcon-root': {
                            height: '80px',
                            width: '80px',
                            color: '#fff',
                        },
                        '& .MuiStepIcon-text': {
                            fill: '#000',
                            fontFamily: 'Orelega One',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            textTransform: 'uppercase',
                        },
                        '& .MuiStepLabel-root': {
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            maxWidth: '355px',
                        }
                    }}>
                        <StepLabel sx={{

                        }}>
                            <div className='TimeLineStepLabel__container'>
                                <h4 className='TimeLineStepLabel__title'>évaluer votre besoin</h4>
                                <p className='TimeLineStepLabel__description'>
                                    Vous remplisser le formulaire de devis en ligne. Nos Experts en déménagement analysent votre besoin.
                                </p>
                            </div>
                        </StepLabel>
                    </Step>
                    <Step sx={{
                        '& .MuiStepIcon-root': {
                            zIndex: '1',
                        },
                        '& .MuiSvgIcon-root': {
                            height: '80px',
                            width: '80px',
                            color: '#fff',
                        },
                        '& .MuiStepIcon-text': {
                            fill: '#000',
                            fontFamily: 'Orelega One',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            textTransform: 'uppercase',
                        },
                        '& .MuiStepLabel-root': {
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            maxWidth: '355px',
                        }
                    }}>
                        <StepLabel>
                            <div className='TimeLineStepLabel__container'>
                                <h4 className='TimeLineStepLabel__title'>RECEVEZ VOTRE DEVIS GRATUIT</h4>
                                <p className='TimeLineStepLabel__description'>
                                    Après analyse de votre besoin, un devis personnalisé vous sera envoyer par mel au meilleur prix.
                                </p>
                            </div>
                        </StepLabel>
                    </Step>
                    <Step sx={{
                        '& .MuiStepIcon-root': {

                            zIndex: '1',
                        },
                        '& .MuiSvgIcon-root': {
                            height: '80px',
                            width: '80px',
                            color: '#fff',
                        },
                        '& .MuiStepIcon-text': {
                            fill: '#000',
                            fontFamily: 'Orelega One',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            textTransform: 'uppercase',
                        },
                        '& .MuiStepLabel-root': {
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            maxWidth: '355px',
                        }
                    }}>
                        <StepLabel>
                            <div className='TimeLineStepLabel__container'>
                                <h4 className='TimeLineStepLabel__title'>Mise en relation avec votre demenageur</h4>
                                <p className='TimeLineStepLabel__description'>
                                    Si le devis vous satisfait et que vous le valdier, vous serez mis en relation avec votre déménageur.
                                </p>
                            </div>
                        </StepLabel>
                    </Step>
                    <Step sx={{
                        '& .MuiStepIcon-root': {
                            zIndex: '1',
                        },
                        '& .MuiSvgIcon-root': {
                            height: '80px',
                            width: '80px',
                            color: '#fff',
                        },
                        '& .MuiStepIcon-text': {
                            fill: '#000',
                            fontFamily: 'Orelega One',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            textTransform: 'uppercase',
                        },
                        '& .MuiStepLabel-root': {
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            maxWidth: '355px',
                        }
                    }}>
                        <StepLabel>
                            <div className='TimeLineStepLabel__container'>
                                <h4 className='TimeLineStepLabel__title'>LAISSER VOUS DEMENAGER</h4>
                                <p className='TimeLineStepLabel__description'>
                                    votre déménageur se présente le jour J. Nos experts en déménagement reste à vos côtés jusqu’a votre enmenagement.
                                </p>
                            </div>
                        </StepLabel>
                    </Step>
                </Stepper>
            </ScrollAnimation>
        </div>
    );
}

export default Timeline;