import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import { useState, useEffect } from 'react';

const GenericCheckboxGroup = ({ name, options, value, onChange, error, helperText }) => {
    const [selectedValues, setSelectedValues] = useState(value || []);

    useEffect(() => {
        setSelectedValues(value);
    }, [value]);

    const handleSelectionChange = (optionValue) => {
        const newSelectedValues = selectedValues.includes(optionValue)
            ? selectedValues.filter((value) => value !== optionValue)
            : [...selectedValues, optionValue];

        setSelectedValues(newSelectedValues);
        onChange(newSelectedValues);
    };

    return (
        <FormControl component="fieldset" error={error} sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '280px'
        }}>
            {options.map((option, index) => (
                <FormControlLabel
                    key={index}
                    control={
                        <Checkbox
                            checked={selectedValues.includes(option.value)}
                            onChange={() => handleSelectionChange(option.value)}
                            sx={{
                                color: '#FDC500',
                                '&.Mui-checked': {
                                    color: 'white',
                                },
                            }}
                        />
                    }
                    label={option.label}
                    sx={{
                        color: '#FDC500',
                        '& .Mui-checked': {
                            color: 'white',
                        },
                    }}
                />
            ))}
            <FormHelperText sx={{ marginLeft: "0", color: 'red !important'}}>{error && helperText}</FormHelperText>
        </FormControl>
    );
};

export default GenericCheckboxGroup;
