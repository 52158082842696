import './App.css';
import 'animate.css';
import 'react-toastify/dist/ReactToastify.css';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ToastContainer } from 'react-toastify';

import Layout from './components/Layout/Layout';
import Header from './components/Header/Header';
import BannerHome from './components/BannerHome/BannerHome';
import Timeline from './components/TimeLine/Timeline';
import Offers from './components/Offers/Offers';
import Guarantees from './components/Guarentees/Guarentees';
import Quote from './components/Quote/Quote';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';


function App() {
  return (
    <div id="Accueil" className="App">
      <a href="#Accueil" className="stt" title="scroll to top">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
          <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z" />
        </svg>
      </a>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Layout>
           <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          transition="bounce"
        />
          <Header />
          <BannerHome />
          <Timeline />
          <Offers />
          <Guarantees />
          <Quote />
          <Contact />
          <Footer />
        </Layout>
      </LocalizationProvider>
    </div>

  );
}

export default App;
