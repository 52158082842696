import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { InputLabel } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText'
import Box from '@mui/material/Box';

const CustomTextField = styled(TextField)({
    width: '100%',
    color: 'white',
    '& label.MuiInputLabel-root': {
        color: '#FDC500',
        fontWeight: 550,
    },
    '& label.Mui-focused': {
        color: '#FDC500',
        fontWeight: 'bold',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#B2BAC2',
    },
    '& input': {
        color: 'white',
    },
    '& textarea': {
        color: 'white',
    },
});

const GenericTextField = (
    { id, label, value, onChange, error, helperText, ...props }) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            width : '100%',
        }}>
            <InputLabel htmlFor={id} sx={{
                alignSelf: 'flex-start',
                color: '#FDC500',
                fontWeight: 'bold',
                padding: 0,
                margin: 0,
                fontFamily: 'Zilla Slab',
            }}>{label}</InputLabel>

            <CustomTextField
                id={id}
                name={label}
                labelId={id}
                value={value}
                onChange={onChange}
                error={error}
                sx={{
                    width: '100%',
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: error ? 'red' : 'white',
                        },
                        '&:hover fieldset': {
                            borderColor: error ? 'red' : 'white',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: error ? 'red' : 'white',
                        },
                    },
                }}
                variant='outlined'
                {...props}
            />
            <FormHelperText sx={{
                color: 'red',
                marginLeft: '4px',
            }}>
                {helperText}
            </FormHelperText>
        </Box>
    );
}

export default GenericTextField;