import './Footer.css';
import ScrollAnimation from 'react-animate-on-scroll';

const Footer = () => {
    return (
        <footer className="Footer">
            <div className="Footer__container">
                <div className="Footer__logo">
                    <ScrollAnimation duration={5}
                        animateIn='animate__tada'
                        >
                        <img src='/images/footer-logo.png' alt="111 déménageurs" height={234} width={144} />
                    </ScrollAnimation>
                </div>
                <div className="Footer__info">
                    <p className="Footer__info--text">© 2024 Les 111 Déménageurs</p>
                    <p className="Footer__info--text">Tous droits réservés</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;