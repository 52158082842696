import './Quote.css';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';

import GenericTextField from '../Generics/TextField/TextField';
import GenericSelect from '../Generics/Select/Select';
import GenericDateField from '../Generics/DateField/DateField';
import GenericRadioGroup from '../Generics/RadioGroup/RadioGroup';

import ScrollAnimation from 'react-animate-on-scroll';
import { useState } from 'react';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

const validationSchema = Yup.object({
    civilite: Yup.string().oneOf(['Mr', 'Mme'], 'Le choix de civilité est invalide (Mr ou Mme attendu)').required('Le champ civilité est requis'),
    firstName: Yup.string().required('Le champ prénom est requis'),
    lastName: Yup.string().required('Le champ nom est requis'),
    email: Yup.string().email('L’adresse email est invalide').required('Le champ email est requis'),
    phone: Yup.string()
        .matches(/^(?:\+33|0)[1-9](?:[ .-]?[0-9]{2}){4}$/, 'Le format du téléphone est invalide (ex : 06 14 14 14 14 ou +336 14 14 14 14)')
        .required('Le champ téléphone est requis'),
    message: Yup.string().required('Le champ message est requis'),

    departureAddress: Yup.string().required('Le champ adresse de départ est requis'),
    departureCity: Yup.string().required('Le champ ville de départ est requis'),
    departurePostalCode: Yup.string().matches(/^[0-9]{5}$/, 'Le format du code postal est invalide (5 chiffres attendus)').required('Le champ code postal de départ est requis'),
    departureCountry: Yup.string().required('Le champ pays de départ est requis'),
    departureFloor: Yup.string().required('Le champ étage de départ est requis'),
    departureElevator: Yup.string().required('Le champ ascenseur de départ est requis'),
    departureMonteCharge: Yup.string().required('Le champ monte-charge de départ est requis'),
    departureAddressComplement: Yup.string().required('Le champ complément d’adresse de départ est requis'),
    superficie: Yup.number().typeError('La superficie doit être un nombre').required('Le champ superficie est requis'),
    volume: Yup.number().typeError('Le volume doit être un nombre').required('Le champ volume est requis'),

    arrivalAddress: Yup.string().required('Le champ adresse d’arrivée est requis'),
    arrivalCity: Yup.string().required('Le champ ville d’arrivée est requis'),
    arrivalPostalCode: Yup.string().matches(/^[0-9]{5}$/, 'Le format du code postal est invalide (5 chiffres attendus)').required('Le champ code postal d’arrivée est requis'),
    arrivalCountry: Yup.string().required('Le champ pays d’arrivée est requis'),
    arrivalFloor: Yup.string().required('Le champ étage d’arrivée est requis'),
    arrivalElevator: Yup.string().required('Le champ ascenseur d’arrivée est requis'),
    arrivalMonteCharge: Yup.string().required('Le champ monte-charge d’arrivée est requis'),
    arrivalAddressComplement: Yup.string().required('Le champ complément d’adresse d’arrivée est requis'),

    formule: Yup.array()
        .of(
            Yup.string().oneOf(['eco', 'standard', 'luxe'], 'Le choix de formule est invalide (eco, standard ou luxe attendu)')
        )
        .min(1, 'Le champ formule ne peut pas être vide')
        .required('Le champ formule est requis'),
    departureDate: Yup.date().required('La date de départ est invalide').required('Le champ date de départ est requis'),
    arrivalDate: Yup.date().required('La date d’arrivée est invalide').required('Le champ date d’arrivée est requis'),
});

const Quote = () => {
    const [isLoading, setIsLoading] = useState(false);

    const handleValidate = async (values) => {
        try {
            setIsLoading(true);

            await axios.post('https://europe-west1-sound-service-416908.cloudfunctions.net/devisForm', {
                civilite: values.civilite,
                prenom: values.firstName,
                nom: values.lastName,
                email: values.email,
                phone: values.phone,
                message: values.message,

                departureAdresse: values.departureAddress,
                departureCity: values.departureCity,
                departureZip: values.departurePostalCode,
                departureCountry: values.departureCountry,
                departureFloor: values.departureFloor,
                departureHasElevator: values.departureElevator,
                departureHasMonteMeubles: values.departureMonteCharge,
                departureComplement: values.departureAddressComplement,
                departureSuperficie: values.superficie,
                departureVolume: values.volume,

                arrivalAdresse: values.arrivalAddress,
                arrivalCity: values.arrivalCity,
                arrivalZip: values.arrivalPostalCode,
                arrivalCountry: values.arrivalCountry,
                arrivalFloor: values.arrivalFloor,
                arrivalHasElevator: values.arrivalElevator,
                arrivalHasMonteMeubles: values.arrivalMonteCharge,
                arrivalComplement: values.arrivalAddressComplement,

                formula: values.formule,
                departureDate: dayjs(values.departureDate).format('DD/MM/YYYY'),
                arrivalDate: dayjs(values.arrivalDate).format('DD/MM/YYYY'),
            })
            setIsLoading(false);
            toast.success('Votre demande de devis a bien été envoyée');

            //reset form
            formik.resetForm();
        } catch (error) {
            setIsLoading(false);
            toast.error('Une erreur est survenue lors de l\'envoi de votre demande de devis');
            console.log(error);
        }
    };

    const formik = useFormik({
        initialValues: {
            civilite: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            message: '',


            departureAddress: '',
            departureCity: '',
            departurePostalCode: '',
            departureCountry: '',
            departureFloor: '',
            departureElevator: '',
            departureMonteCharge: '',
            departureAddressComplement: '',
            superficie: '',
            volume: '',


            arrivalAddress: '',
            arrivalCity: '',
            arrivalPostalCode: '',
            arrivalCountry: '',
            arrivalFloor: '',
            arrivalElevator: '',
            arrivalMonteCharge: '',
            arrivalAddressComplement: '',


            formule: [],
            departureDate: undefined,
            arrivalDate: undefined,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            await handleValidate(values);
        },
    });

    return (
        <div id="Devis" className="Quote">
            <form onSubmit={formik.handleSubmit}>
                <h2 className="Quote__title">VOTRE DEVIS</h2>
                <ScrollAnimation animateIn='animate__fadeIn' animateOnce={true} style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <h3 className="Quote__subtitle">Indiquer vos coordonnées</h3>

                    <div className="Quote__step">
                        <GenericSelect
                            id="civilite"
                            name="civilite"
                            label="Civilité"
                            value={formik.values.civilite}
                            onChange={formik.handleChange}
                            error={formik.touched.civilite && Boolean(formik.errors.civilite)}
                            helperText={formik.touched.civilite && formik.errors.civilite}
                        >
                            <MenuItem value="Mr">Mr</MenuItem>
                            <MenuItem value="Mme">Mme</MenuItem>
                        </GenericSelect>

                        <GenericTextField
                            id="firstName"
                            name="firstName"
                            label="Prénom"
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                            helperText={formik.touched.firstName && formik.errors.firstName}
                        />

                        <GenericTextField
                            id="lastName"
                            name="lastName"
                            label="Nom"
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                            helperText={formik.touched.lastName && formik.errors.lastName}
                        />

                        <GenericTextField
                            id="email"
                            name="email"
                            label="Email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />

                        <GenericTextField
                            id="phone"
                            name="phone"
                            label="Téléphone"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            error={formik.touched.phone && Boolean(formik.errors.phone)}
                            helperText={formik.touched.phone && formik.errors.phone}
                        />

                        <GenericTextField
                            id="message"
                            name="message"
                            label="Message"
                            value={formik.values.message}
                            onChange={formik.handleChange}
                            error={formik.touched.message && Boolean(formik.errors.message)}
                            helperText={formik.touched.message && formik.errors.message}
                        />
                    </div>

                    <h3 className="Quote__subtitle">InformationS DE DEPART</h3>

                    <div className="Quote__step">
                        <GenericTextField
                            id="departureAddress"
                            name="departureAddress"
                            label="Adresse de départ"
                            value={formik.values.departureAddress}
                            onChange={formik.handleChange}
                            error={formik.touched.departureAddress && Boolean(formik.errors.departureAddress)}
                            helperText={formik.touched.departureAddress && formik.errors.departureAddress}
                        />

                        <GenericTextField
                            id="departureCity"
                            name="departureCity"
                            label="Ville de départ"
                            value={formik.values.departureCity}
                            onChange={formik.handleChange}
                            error={formik.touched.departureCity && Boolean(formik.errors.departureCity)}
                            helperText={formik.touched.departureCity && formik.errors.departureCity}
                        />

                        <GenericTextField
                            id="departurePostalCode"
                            name="departurePostalCode"
                            label="Code postal de départ"
                            value={formik.values.departurePostalCode}
                            onChange={formik.handleChange}
                            error={formik.touched.departurePostalCode && Boolean(formik.errors.departurePostalCode)}
                            helperText={formik.touched.departurePostalCode && formik.errors.departurePostalCode}
                        />

                        <GenericTextField
                            id="departureCountry"
                            name="departureCountry"
                            label="Pays de départ"
                            value={formik.values.departureCountry}
                            onChange={formik.handleChange}
                            error={formik.touched.departureCountry && Boolean(formik.errors.departureCountry)}
                            helperText={formik.touched.departureCountry && formik.errors.departureCountry}
                        />

                        <GenericTextField
                            id="departureFloor"
                            name="departureFloor"
                            label="Etage de départ"
                            value={formik.values.departureFloor}
                            onChange={formik.handleChange}
                            error={formik.touched.departureFloor && Boolean(formik.errors.departureFloor)}
                            helperText={formik.touched.departureFloor && formik.errors.departureFloor}
                        />

                        <GenericSelect
                            id="departureElevator"
                            name="departureElevator"
                            label="Ascenseur de départ"
                            value={formik.values.departureElevator}
                            onChange={formik.handleChange}
                            error={formik.touched.departureElevator && Boolean(formik.errors.departureElevator)}
                            helperText={formik.touched.departureElevator && formik.errors.departureElevator}
                        >
                            <MenuItem value="yes">Oui</MenuItem>
                            <MenuItem value="no">Non</MenuItem>
                        </GenericSelect>

                        <GenericSelect
                            id="departureMonteCharge"
                            name="departureMonteCharge"
                            label="Monte-charge de départ"
                            value={formik.values.departureMonteCharge}
                            onChange={formik.handleChange}
                            error={formik.touched.departureMonteCharge && Boolean(formik.errors.departureMonteCharge)}
                            helperText={formik.touched.departureMonteCharge && formik.errors.departureMonteCharge}
                        >
                            <MenuItem value="yes">Oui</MenuItem>
                            <MenuItem value="no">Non</MenuItem>
                        </GenericSelect>

                        <GenericTextField
                            id="departureAddressComplement"
                            name="departureAddressComplement"
                            label="Complément d'adresse de départ"
                            value={formik.values.departureAddressComplement}
                            onChange={formik.handleChange}
                            error={formik.touched.departureAddressComplement && Boolean(formik.errors.departureAddressComplement)}
                            helperText={formik.touched.departureAddressComplement && formik.errors.departureAddressComplement}
                        />

                        <GenericTextField
                            id="superficie"
                            name="superficie"
                            label="Superficie"
                            value={formik.values.superficie}
                            onChange={formik.handleChange}
                            error={formik.touched.superficie && Boolean(formik.errors.superficie)}
                            helperText={formik.touched.superficie && formik.errors.superficie}
                        />

                        <GenericTextField
                            id="volume"
                            name="volume"
                            label="Volume"
                            value={formik.values.volume}
                            onChange={formik.handleChange}
                            error={formik.touched.volume && Boolean(formik.errors.volume)}
                            helperText={formik.touched.volume && formik.errors.volume}
                        />

                        <h3 className="Quote__subtitle">InformationS D'ARRIVEE</h3>

                        <GenericTextField
                            id="arrivalAddress"
                            name="arrivalAddress"
                            label="Adresse d'arrivée"
                            value={formik.values.arrivalAddress}
                            onChange={formik.handleChange}
                            error={formik.touched.arrivalAddress && Boolean(formik.errors.arrivalAddress)}
                            helperText={formik.touched.arrivalAddress && formik.errors.arrivalAddress}
                        />

                        <GenericTextField
                            id="arrivalCity"
                            name="arrivalCity"
                            label="Ville d'arrivée"
                            value={formik.values.arrivalCity}
                            onChange={formik.handleChange}
                            error={formik.touched.arrivalCity && Boolean(formik.errors.arrivalCity)}
                            helperText={formik.touched.arrivalCity && formik.errors.arrivalCity}
                        />

                        <GenericTextField
                            id="arrivalPostalCode"
                            name="arrivalPostalCode"
                            label="Code postal d'arrivée"
                            value={formik.values.arrivalPostalCode}
                            onChange={formik.handleChange}
                            error={formik.touched.arrivalPostalCode && Boolean(formik.errors.arrivalPostalCode)}
                            helperText={formik.touched.arrivalPostalCode && formik.errors.arrivalPostalCode}
                        />

                        <GenericTextField
                            id="arrivalCountry"
                            name="arrivalCountry"
                            label="Pays d'arrivée"
                            value={formik.values.arrivalCountry}
                            onChange={formik.handleChange}
                            error={formik.touched.arrivalCountry && Boolean(formik.errors.arrivalCountry)}
                            helperText={formik.touched.arrivalCountry && formik.errors.arrivalCountry}
                        />

                        <GenericTextField
                            id="arrivalFloor"
                            name="arrivalFloor"
                            label="Etage d'arrivée"
                            value={formik.values.arrivalFloor}
                            onChange={formik.handleChange}
                            error={formik.touched.arrivalFloor && Boolean(formik.errors.arrivalFloor)}
                            helperText={formik.touched.arrivalFloor && formik.errors.arrivalFloor}
                        />

                        <GenericSelect
                            id="arrivalElevator"
                            name="arrivalElevator"
                            label="Ascenseur d'arrivée"
                            value={formik.values.arrivalElevator}
                            onChange={formik.handleChange}
                            error={formik.touched.arrivalElevator && Boolean(formik.errors.arrivalElevator)}
                            helperText={formik.touched.arrivalElevator && formik.errors.arrivalElevator}
                        >
                            <MenuItem value="yes">Oui</MenuItem>
                            <MenuItem value="no">Non</MenuItem>
                        </GenericSelect>

                        <GenericSelect
                            id="arrivalMonteCharge"
                            name="arrivalMonteCharge"
                            label="Monte-charge d'arrivée"
                            value={formik.values.arrivalMonteCharge}
                            onChange={formik.handleChange}
                            error={formik.touched.arrivalMonteCharge && Boolean(formik.errors.arrivalMonteCharge)}
                            helperText={formik.touched.arrivalMonteCharge && formik.errors.arrivalMonteCharge}
                        >
                            <MenuItem value="yes">Oui</MenuItem>
                            <MenuItem value="no">Non</MenuItem>
                        </GenericSelect>

                        <GenericTextField
                            id="arrivalAddressComplement"
                            name="arrivalAddressComplement"
                            label="Complément d'adresse d'arrivée"
                            value={formik.values.arrivalAddressComplement}
                            onChange={formik.handleChange}
                            error={formik.touched.arrivalAddressComplement && Boolean(formik.errors.arrivalAddressComplement)}
                            helperText={formik.touched.arrivalAddressComplement && formik.errors.arrivalAddressComplement}
                        />
                    </div>


                    <h3 className="Quote__subtitle">Information importante</h3>

                    <div className="Quote__step">
                        <GenericRadioGroup
                            name="formule"
                            options={[
                                { value: 'eco', label: 'Formule économique' },
                                { value: 'standard', label: 'Formule standard' },
                                { value: 'luxe', label: 'Formule luxe' },
                            ]}
                            value={formik.values.formule}
                            onChange={(val) => formik.setFieldValue('formule', val, true)}
                            error={formik.touched.formule && Boolean(formik.errors.formule)}
                            helperText={formik.touched.formule && formik.errors.formule}
                        />

                        <GenericDateField
                            id="departureDate"
                            name="departureDate"
                            label="Date de départ"
                            value={formik.values.departureDate}
                            onChange={(val) => formik.setFieldValue('departureDate', val, true)}
                            error={formik.touched.departureDate && Boolean(formik.errors.departureDate)}
                            helperText={formik.touched.departureDate && formik.errors.departureDate}
                        />

                        <GenericDateField
                            id="arrivalDate"
                            name="arrivalDate"
                            label="Date d'arrivée"
                            value={formik.values.arrivalDate}
                            onChange={(val) => formik.setFieldValue('arrivalDate', val, true)}
                            error={formik.touched.arrivalDate && Boolean(formik.errors.arrivalDate)}
                            helperText={formik.touched.arrivalDate && formik.errors.arrivalDate}
                        />
                    </div>

                    <div className="Quote__button">
                        <button className="Button" type="submit" disabled={isLoading}>{isLoading ? <CircularProgress size={12} /> : "Envoyer"}</button>
                    </div>
                </ScrollAnimation>

            </form>
        </div>
    )
}

export default Quote;