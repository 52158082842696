import './Guarentees.css';

import ScrollAnimation from 'react-animate-on-scroll';

const Guarantees = () => {
    return (
        <div id="Garanties" className='Guarentees'>
            <h2 className='Guarentees__title'>Nos garanties</h2>


            <ScrollAnimation animateIn='animate__slideInLeft'
                duration={1.5} animateOnce={true}>

                <div className='Guarentees__container'>
                    <div className='Guarentee'>
                        <img src="/images/ssl.png" alt="Protection des données" />
                        <h4 className="Guarentee__title">Protection des données</h4>
                        <p className='Guarentee__description'>Les 111 déménageurs, s'engage à protéger vos informations, avec la même diligence que nous protégeons vos biens lors d'un déménagement, en ayant un système de protection anti-piratage : SSL</p>
                    </div>

                    <div className='Guarentee'>
                        <img src="/images/insurance.png" alt="Assurance du déménageur" />
                        <h4 className="Guarentee__title">Assurance du déménageur</h4>
                        <p className='Guarentee__description'>La sécurité de vos biens est notre expertise. Nous utilisons des matériaux d'emballage de haute qualité et des méthodes sûres pour garantir une protection maximale pendant le transport.</p>
                    </div>

                    <div className='Guarentee'>
                        <img src="/images/recycle.png" alt="Engagement" />
                        <h4 className="Guarentee__title">Engagement</h4>
                        <p className='Guarentee__description'>Notre entreprise s'engage pour l'écologie en privilégiant l'utilisation de matériel de déménagement totalement ou partiellement recyclé.
                            Recommandés pour notre professionnalisme exceptionnel par nos clients..</p>

                    </div>


                    <div className='Guarentee'>
                        <img src="/images/restfulness.png" alt="Tranquillité" />
                        <h4 className="Guarentee__title">Tranquillité</h4>
                        <p className='Guarentee__description'>Optez pour la tranquillité d'esprit avec notre assurance complète, garantit un déménagement sans souci.</p>
                    </div>

                    <div className='Guarentee'>
                        <img src="/images/contract.png" alt="Contrat" />
                        <h4 className="Guarentee__title">Contrat</h4>
                        <p className='Guarentee__description'>Les 111 déménageurs incluent une assurance dans votre contrat, couvrant tous les risques pour votre mobilier selon sa valeur déclarée, pendant tout le déménagement.</p>
                    </div>

                    <div className='Guarentee'>
                        <img src="/images/support.png" alt="Service client" />
                        <h4 className="Guarentee__title">Service client</h4>
                        <p className='Guarentee__description'>Les 111 déménageurs, vous proposent un support technique pour répondre à vos demandes disponible 24h/24 - 7j/7 : voir Page Contactez-nous.</p>
                    </div>
                </div>
            </ScrollAnimation>
        </div>
    );
}

export default Guarantees;
