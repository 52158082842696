import './Header.css';

const Header = () => {
    return (
        <header className='Header'>
            <img src='/images/logo.png'  alt="logo" className='Logo' />
            <nav className='Navbar'>
                <ul className='Menu'>
                    <li><a href="#Accueil">Accueil</a></li>
                    <li><a href="#Formules">Formules</a></li>
                    <li><a href="#Garanties">Garanties</a></li>
                    <li><a href="#Devis">Devis</a></li>
                    <li><a href="#Contact">Contact</a></li>
                </ul>
            </nav>

        </header>
    )
}

export default Header;