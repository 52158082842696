import { InputLabel } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';

const GenericDateField = ({ id, label, value, onChange, error, helperText, children, ...props }) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            width : '100%',
        }}>
            <InputLabel htmlFor={id} sx={{
                alignSelf: 'flex-start',
                color: '#FDC500',
                fontWeight: 'bold',
                padding: 0,
                margin: 0,
                fontFamily: 'Zilla Slab',
            }}>{label}</InputLabel>
            <DatePicker
                id={id}
                name={label}
                value={value}
                onChange={onChange}
                error={error}
                helperText={helperText}
                sx={{
                    width: '100%',
                    borderRadius: '4px',
                    color: '#FDC500',
                    '& label.Mui-focused': {
                        color: 'white',
                    },
                    '& label': {
                        color: 'white',
                    },
                    '& .MuiSvgIcon-root': {
                        color: 'white',
                    },
                    '& .MuiTextField-root': {
                        border: '1px solid white !important',
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: error ? 'red' : 'white',
                        },
                        '&:hover fieldset': {
                            borderColor: error ? 'red' : 'white',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: error ? 'red' : 'white',
                        },
                    },
                    '& input': {
                        color: 'white',
                    },
                }}
            />
            <FormHelperText sx={{
                color: 'red',
                marginLeft: '4px',
            }}>
                {helperText}
            </FormHelperText>
        </Box>
    );
}

export default GenericDateField;