import './BannerHome.css'

import MovingComponent  from 'react-moving-text'

const BannerHome = () => {
    const scrollToQuote = () => {
        const section = document.querySelector( '#Devis' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start', } );
      };

    return (
        <div id="Accueil" className="bannerHome">
            <div className="bannerHome__container_left">
                    <h1 className="BannerTitle">
                    <MovingComponent
                    type="slideInFromTop"
                    duration="1000ms"
                    delay="0s"
                    direction="normal"
                    timing="ease"
                    iteration="1"
                    fillMode="none">
                        Votre déménagement est entre de bonne mains
                    </MovingComponent>
                    </h1>
                    <button className="Button" onClick={() => scrollToQuote() }>Obtenir un devis</button>
            </div>
            <div className="bannerHome__container_right">
                <img src='/images/banner.png' alt="Déménagement" className="animate__animated animate__fadeIn" style={{
                    objectFit: 'contain',
                }} />    
            </div>
        </div>
    );
}

export default BannerHome;
