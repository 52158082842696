import './Offers.css';

import ScrollAnimation from 'react-animate-on-scroll';


const Offers = () => {
    return (
        <div id="Formules" className='Offers'>
            <h2 className="Offers__title">Nos formules</h2>

            <ScrollAnimation animateIn='animate__slideInRight'
                duration={1.5} animateOnce={true}>
                <div className='Offers__container'>
                    <div className='Offer'>
                        <h4 className="Offer__title">Formule économique</h4>
                        <div className='Offer__container'>
                            <img src="/images/eco.png" alt="Formule économique" />
                        </div>
                        <article>
                            <ul className="Offer__details__list">
                                <li>Support technique 7j/7 </li>
                                <li>Protection des meubles et des literies</li>
                                <li>Emballage et déballage des vêtements sur penderies</li>
                                <li>Chargement, transport et déchargement de vos cartons</li>
                            </ul>
                        </article>
                    </div>

                    <div className='Offer'>
                        <h4 className="Offer__title">Formule standard</h4>
                        <div className='Offer__container'>
                            <img src="/images/standard.png" alt="Formule standard" />
                        </div>
                        <article>
                            <ul className="Offer__details__list">
                                <li>Support technique 7j/7 </li>
                                <li>Protection des meubles et des literies</li>
                                <li>Emballage et déballage des objets fragiles</li>
                                <li>Emballage et déballage des vêtements sur penderies</li>
                                <li>Chargement, transport et déchargement de vos cartons</li>
                                <li>Démontage et remontage des meubles qui ne passent pas</li>
                            </ul>
                        </article>
                    </div>

                    <div className='Offer'>
                        <h4 className="Offer__title">Formule luxe</h4>
                        <div className='Offer__container'>
                            <img src="/images/luxe.png" alt="Formule luxe" />
                        </div>
                        <article>
                            <ul className="Offer__details__list">
                                <li>Support technique 7j/7 </li>
                                <li>Protection des meubles et des literies</li>
                                <li>Emballage de vos affaires non-fragiles</li>
                                <li>Emballage et déballage des objets fragiles</li>
                                <li>Emballage et déballage des vêtements sur penderies</li>
                                <li>Chargement, transport et déchargement de vos cartons</li>
                                <li>Démontage et remontage des meubles qui ne passent pas</li>
                            </ul>
                        </article>
                    </div>

                </div>
            </ScrollAnimation>



        </div>
    );
}

export default Offers;
