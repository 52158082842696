import './Contact.css';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { useState } from 'react';
import { toast } from 'react-toastify';

import GenericTextField from '../Generics/TextField/TextField';

const validationSchema = Yup.object({
    firstNameContact: Yup.string().required('Le champ prénom est requis'),
    lastNameContact: Yup.string().required('Le champ nom est requis'),
    emailContact: Yup.string().email('L’adresse email est invalide').required('Le champ email est requis'),
    messageContact: Yup.string()
        .max(50, 'Doit contenir 50 caractères ou moins')
        .required('Le champ message est requis'),
});

const Contact = () => {
    const [isLoading, setIsLoading] = useState(false);

    const handleValidate = (values) => {
        setIsLoading(true);
        axios.post('https://europe-west1-sound-service-416908.cloudfunctions.net/contact', {
            nom: values.lastNameContact,
            prenom: values.firstNameContact,
            email: values.emailContact,
            message: values.messageContact,
        })
            .then((response) => {
                //reset form
                formik.resetForm();
                setIsLoading(false);
                toast.success('Votre message a été envoyé avec succès');
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error('Une erreur est survenue, veuillez réessayer plus tard');
            });
    }

    const formik = useFormik({
        initialValues: {
            firstNameContact: '',
            lastNameContact: '',
            emailContact: '',
            messageContact: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            await handleValidate(values);
        },
    });


    return (
        <div id="Contact" className="Contact">
            <h1 className="Contact__title">NOUS CONTACTER</h1>
            <p className="Contact__description">
                Des questions ? Un problème ?<br />
                assure des réponses rapides et efficaces.<br />
                Remplissez le formulaire ci-dessous pour un service personnalisé !<br />
            </p>

            <div className="Contact__panel__containers">
                <div className="Contact__left__container">
                    <div className="Contact__left__container__map">
                        <div className="Contact__icon">
                            <img src="/images/icon-map.png" alt="Map" height={40} width={28} />
                        </div>
                        <p className="Contact__left__container__description">
                            19 RUE DES BALKANS 75020 PARIS
                        </p>
                    </div>
                    <div className="Contact__left__container__map">
                        <div className="Contact__icon">
                            <img src="/images/icon-tel.png" alt="Tel" height={40} />
                        </div>
                        <p className="Contact__left__container__description">
                            06 83 76 71 24 - 07 56 98 23 11
                        </p>
                    </div>
                    <div className="Contact__left__container__map">
                        <div className="Contact__icon">
                            <img src="/images/icon-email.png" alt="Email" height={18} width={27} />
                        </div>
                        <p className="Contact__left__container__description">
                            contact@les111demenageurs.fr
                        </p>
                    </div>
                </div>
                <div className="Contact__right__container">
                    <form onSubmit={formik.handleSubmit}>
                        <GenericTextField
                            id="firstNameContact"
                            name="firstNameContact"
                            label="Prénom"
                            value={formik.values.firstNameContact}
                            onChange={formik.handleChange}
                            error={formik.touched.firstNameContact && Boolean(formik.errors.firstNameContact)}
                            helperText={formik.touched.firstNameContact && formik.errors.firstNameContact}
                        />

                        <GenericTextField
                            id="lastNameContact"
                            name="lastNameContact"
                            label="Nom"
                            value={formik.values.lastNameContact}
                            onChange={formik.handleChange}
                            error={formik.touched.lastNameContact && Boolean(formik.errors.lastNameContact)}
                            helperText={formik.touched.lastNameContact && formik.errors.lastNameContact}
                        />

                        <GenericTextField
                            id="emailContact"
                            name="emailContact"
                            label="Email"
                            value={formik.values.emailContact}
                            onChange={formik.handleChange}
                            error={formik.touched.emailContact && Boolean(formik.errors.emailContact)}
                            helperText={formik.touched.emailContact && formik.errors.emailContact}
                        />

                        <GenericTextField
                            id="messageContact"
                            name="messageContact"
                            label="Message"
                            value={formik.values.messageContact}
                            onChange={formik.handleChange}
                            error={formik.touched.messageContact && Boolean(formik.errors.messageContact)}
                            helperText={formik.touched.messageContact && formik.errors.messageContact}
                            multiline
                            rows={6}
                        />

                        <div className="Contact_button">
                            <button className="Button" type="submit" disabled={isLoading}>{isLoading ? <CircularProgress size={12} /> : "Envoyer"}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
export default Contact;