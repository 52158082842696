import React from 'react';

const Layout = ({ children }) => {
  // Define the container style with a maximum width of 1680px
  const containerStyle = {
    maxWidth: '1440px',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: '20px', // Add padding to ensure content is not touching the screen edges
    paddingRight: '20px',
    backgroundColor: '#00296B',
    height: '100%',
    width: '100%,',
    display:  'flex',
    flexDirection: 'column',
    gap: '60px',
  };

  return (
    <div style={containerStyle}>
      {children}
    </div>
  );
};

export default Layout;
