import { styled } from '@mui/material/styles';
import { InputLabel } from '@mui/material';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';

const CustomSelect = styled(Select)({
    width: '100%',
    color: '#FDC500',
    '& .MuiSvgIcon-root': {
        color: 'white',
    },
    '& .MuiSelect-select': {
        color: 'white',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
});


const GenericSelect = (
    { id, label, value, onChange, error, helperText, children, ...props }) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            width : '100%',
        }}>
            <InputLabel htmlFor={id} sx={{
                alignSelf: 'flex-start',
                color: '#FDC500',
                fontWeight: 'bold',
                padding: 0,
                margin: 0,
                fontFamily: 'Zilla Slab',

            }}>{label}</InputLabel>

            <CustomSelect
                id={id}
                name={label}
                labelId={id}
                value={value}
                onChange={onChange}
                error={error}
                sx={{
                    width: '100%',
                    border: error ? '1px solid red' : '1px solid #D9D9D9',
                }}
                variant='outlined'
                {...props}
            >
                {children}
            </CustomSelect>
            <FormHelperText sx={{
                color: 'red',
                marginLeft: '4px',
            }}>
                {helperText}
            </FormHelperText>
        </Box>
    );
}

export default GenericSelect;

